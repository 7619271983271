"use client";
import { useCurrentLocale } from "@/locales/client";
import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

const CalForm = () => {
  const lang = useCurrentLocale();

  useEffect(() => {
    (async function () {
      const namespace = lang === "fr" ? "appeldecouverte" : "discovercall";
      const cal = await getCalApi({ namespace });
      cal("ui", {
        "styles": { "branding": { "brandColor": "#6f42c1" } },
        "hideEventTypeDetails": false,
        "layout": "month_view",
      });
    })();
  }, [lang]);

  return (
    <Cal
      namespace={lang === "fr" ? "appeldecouverte" : "discovercall"}
      calLink={lang === "fr" ? "sacstudio/appeldecouverte" : "sacstudio/discovercall"}
      style={{ width: "100%", height: "100%", overflow: "scroll" }}
      config={{ layout: 'month_view' }}
    />
  );
};

export default CalForm;

