"use client";

import React, { useState, useEffect } from 'react';
import LeadMagnetModal from '../modals/LeadMagnetModal';
import ComedianModal from '../modals/ComedianModal';
import { config } from '../../../site.config';

type ClientPopupManagerProps = {
  popupComedienId?: string;
  triggerElementId: string;  // ID du bouton qui ouvre la pop-up via clic
  scrollTriggerElementId?: string;  // ID de l'élément qui déclenche la pop-up lors du scroll
  initialPopupOpen?: boolean;  // Prop pour déclencher l'ouverture initiale
};

const ClientPopupManager: React.FC<ClientPopupManagerProps> = ({  popupComedienId, triggerElementId, scrollTriggerElementId, initialPopupOpen = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(initialPopupOpen);  // Modifie l'état initial en fonction du prop
  const [isModalOpenedByUser, setIsModalOpenedByUser] = useState(false);  // Marque si l'utilisateur a ouvert la modale
  const [hasModalOpenedOnScroll, setHasModalOpenedOnScroll] = useState(false);  // Marque si la modale a été ouverte par le scroll
  const [comedianModalOpen, setComedianModalOpen] = useState(false);
  const [selectedComedian, setSelectedComedian] = useState<any>(null);


  // Fonction pour ouvrir le modal
  const openModal = () => {
    setIsModalOpen(true);
    setIsModalOpenedByUser(true);  // Empêche l'ouverture via le scroll après un clic manuel
  };

  // Fonction pour fermer le modal
  const closeModal = () => setIsModalOpen(false);

  // Gestion de l'ouverture via clic sur le bouton
  useEffect(() => {
    const triggerElement = document.getElementById(triggerElementId);
    if (triggerElement) {
      triggerElement.addEventListener('click', openModal);
    }

    return () => {
      if (triggerElement) {
        triggerElement.removeEventListener('click', openModal);
      }
    };
  }, [triggerElementId]);

  // Gestion de l'ouverture via le scroll
  useEffect(() => {
    const handleScroll = () => {
      if (scrollTriggerElementId && !isModalOpenedByUser && !hasModalOpenedOnScroll) {
        const scrollElement = document.getElementById(scrollTriggerElementId);
        if (scrollElement) {
          const rect = scrollElement.getBoundingClientRect();
          if (rect.top <= window.innerHeight && rect.bottom >= 0) {
            openModal();
            setHasModalOpenedOnScroll(true);  // Marque que la modale a été ouverte par le scroll
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollTriggerElementId, isModalOpenedByUser, hasModalOpenedOnScroll]);


  useEffect(() => {
    if (popupComedienId) {
      const comedian = config.comedians.find(
        (comedian) => comedian.image === popupComedienId
      );
      if (comedian) {
        setSelectedComedian(comedian);
        setComedianModalOpen(true);
      } else {
        console.error(`Comédien avec l'ID '${popupComedienId}' non trouvé.`);
      }
    }
  }, [popupComedienId]);
  

  return (
    <>
      {/* LeadMagnetModal existant */}
      <LeadMagnetModal isOpen={isModalOpen} onRequestClose={closeModal} />
  
      {/* ComedianModal */}
      {selectedComedian && (
        <ComedianModal
          isOpen={comedianModalOpen}
          onRequestClose={() => setComedianModalOpen(false)}
          comedian={selectedComedian}
        />
      )}
    </>
  );
};

export default ClientPopupManager;
