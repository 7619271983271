"use client";
import enFlag from "@/assets/english.png";
import frFlag from "@/assets/france.png";
import jpFlag from "@/assets/Japan.png";
import ngFlag from "@/assets/Nigeria.png";
import esFlag from "@/assets/Spain.png";
import { cn } from "@/utils/Clsx";
import Image, { StaticImageData } from "next/image";
import React, { FC, HTMLAttributes, useState } from "react";
import ComedianModal from "../modals/ComedianModal";
import { Mic } from 'lucide-react'; 
import { useCurrentLocale } from '../../locales/client'; // Import de la fonction pour obtenir la langue actuelle

export type PeopleCardProps = HTMLAttributes<HTMLDivElement> & {
  nomPrenom: string;
  posteFr: string;
  posteEn: string;
  image: string;
  iconsFr: Array<{
    icon: React.ReactNode;
    lien: string;
  }>;
  iconsEn?: Array<{
    icon: React.ReactNode;
    lien: string;
  }>;
  iconsJp?: Array<{
    icon: React.ReactNode;
    lien: string;
  }>;
  iconsNg?: Array<{
    icon: React.ReactNode;
    lien: string;
  }>;
  iconsEs?: Array<{
    icon: React.ReactNode;
    lien: string;
  }>;
  flag?: Array<"fr" | "en" | "jp" | "ng" | "es">;
  audios: {
    fr: Array<{ src: string; title: string }>;
    en: Array<{ src: string; title: string }>;
    jp?: Array<{ src: string; title: string }>;
    ng?: Array<{ src: string; title: string }>;
    es?: Array<{ src: string; title: string }>;
  };
};

const flagImages = {
  fr: frFlag,
  en: enFlag,
  jp: jpFlag,
  ng: ngFlag,
  es: esFlag,
};

const PeopleCard: FC<PeopleCardProps> = ({
  image,
  nomPrenom,
  posteFr,
  posteEn,
  iconsFr,
  iconsEn,
  iconsJp,
  iconsNg,
  iconsEs,
  flag,
  audios,
  className,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const lang = useCurrentLocale(); // Récupération de la langue actuelle
  const poste = lang === "fr" ? posteFr : posteEn; // Choix du poste en fonction de la langue

  const toggleModal = () => setModalOpen(!isModalOpen);

  return (
    <div className={cn("w-36 aspect-[9/16] flex flex-col items-center gap-4", className)}>
      <div className="relative aspect-square w-24 rounded-full border-2 border-primary">
        <Image width={100} height={100} alt={nomPrenom} src={`/comediens/${image}.webp`} className="aspect-square size-full rounded-full object-cover" />
        {flag?.map((f, index) => (
          <Flag key={f} image={flagImages[f]} position={index} />
        ))}
      </div>
      <p className="h-1/5 text-[16px]">{nomPrenom}</p>
      <p className="flex-1 text-[16px]">{poste}</p>
      <div className="flex gap-4">
        {/* Affichage conditionnel de l'icône Mic */}
        {(audios.fr.length > 0 || 
          audios.en.length > 0 || 
          (audios.jp && audios.jp.length > 0) || 
          (audios.ng && audios.ng.length > 0) || 
          (audios.es && audios.es.length > 0)) && (
            <Mic width={20} height={20} onClick={toggleModal} style={{ cursor: 'pointer' }} />
        )}
        {iconsFr.map((icon, index) => (
          <a aria-label={icon.lien} target="__blank" key={index} href={icon.lien}>
            {icon.icon}
          </a>
        ))}
      </div>

      {isModalOpen && (
        <ComedianModal
          isOpen={isModalOpen}
          onRequestClose={toggleModal}
          comedian={{ nomPrenom, image, posteFr, posteEn, audios }}
        />
      )}
    </div>
  );
};

export default PeopleCard;

const Flag: FC<{ image: StaticImageData; position: number }> = ({ image, position }) => {
  const positions = [
    { top: 18, right: -4 },
    { top: 35, right: -8 },
    { top: 52, right: -6 }, 
  ];

  return (
    <div className="absolute size-4" style={positions[position]}>
      <Image
        className="size-full object-contain"
        src={image}
        width={25}
        height={25}
        alt="drapeau"
      />
    </div>
  );
};
