/* eslint-disable */
import React from 'react';
import Modal from 'react-modal';
import { useCurrentLocale } from '../../locales/client';  // Utiliser pour détecter la langue actuelle

type ThankYouModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};

const ThankYouModal: React.FC<ThankYouModalProps> = ({ isOpen, onRequestClose }) => {
  const lang = useCurrentLocale(); // Détecte la langue actuelle

  const labels = {
    fr: {
      congratulations: 'FÉLICITATIONS !',
      ebookSent: 'Votre Ebook est en route !',
      checkSpams: '(Vérifiez dans vos spams, les messageries n’aiment pas les gros PDF)'
    },
    en: {
      congratulations: 'CONGRATULATIONS !',
      ebookSent: 'Your Ebook is on its way !',
      checkSpams: '(Check your spam folder, some mail services don’t like large PDFs)'
    }
  };

  const text = labels[lang]; // Sélection du texte en fonction de la langue

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Thank You Modal"
      className="fixed inset-0 z-50 flex items-center justify-center p-4"
      overlayClassName="bg-black bg-opacity-75 fixed inset-0 z-40"
      ariaHideApp={false}
    >
      {/* Conteneur principal avec fond dark */}
      <div className="relative w-full max-w-md p-6 text-white rounded-lg shadow-lg" style={{ backgroundColor: '#1d1d1d' }}>
        {/* Bouton de fermeture personnalisé */}
        <button onClick={onRequestClose} aria-label="Close" className="absolute top-4 right-4">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.75 14.25L14.25 21.75M14.25 14.25L21.7499 21.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M33 18C33 25.0711 33 28.6066 30.8033 30.8033C28.6066 33 25.0711 33 18 33C10.9289 33 7.3934 33 5.1967 30.8033C3 28.6066 3 25.0711 3 18C3 10.9289 3 7.3934 5.1967 5.1967C7.3934 3 10.9289 3 18 3C25.0711 3 28.6066 3 30.8033 5.1967C32.2639 6.65731 32.7533 8.70983 32.9173 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
        </button>

        {/* Message de félicitations */}
        <h2 className="mb-4 text-3xl font-bold text-center">{text.congratulations}</h2>
        
        {/* Texte dynamique en violet */}
        <p className="text-center text-xl" style={{ color: '#7C3AED' }}>{text.ebookSent}</p>
        
        {/* Message additionnel */}
        <p className="text-center text-gray-300 mt-2">{text.checkSpams}</p>
      </div>
    </Modal>
  );
};

export default ThankYouModal;
