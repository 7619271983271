/* eslint-disable */
"use client";

import React, { useState } from 'react';
import Modal from 'react-modal';
import Image from 'next/image';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useCurrentLocale } from '../../locales/client';
import ThankYouModal from './ThankYouModal'; // Import du modal de remerciement
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


type LeadMagnetModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onSuccess?: () => void;
};

type FormData = {
  name: string;
  email: string;
};

const LeadMagnetModal: React.FC<LeadMagnetModalProps> = ({ isOpen, onRequestClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>();
  const lang = useCurrentLocale();

  const { executeRecaptcha } = useGoogleReCaptcha(); // Extraction de executeRecaptcha

  const [loading, setLoading] = useState(false);  // Indicateur de chargement
  const [showThankYou, setShowThankYou] = useState(false);  // État pour afficher le pop-up de remerciement

  const labels = {
    fr: {
      title: "Recevez notre E-book",
      subtitle: "Entrez vos informations ci-dessous pour recevoir notre E-book - Ta voix, ton pouvoir !",
      namePlaceholder: "Nom",
      emailPlaceholder: "Email",
      buttonText: loading ? "Envoi..." : "Recevoir le E-book",
      oldPrice: "9,99€",  // Prix rayé
      bottomText: "(Offert pour une durée limitée)",
      errorMessage: "Ce champ est requis",
    },
    en: {
      title: "Get our E-book",
      subtitle: "Enter your information below to receive our E-book - Your voice, your power!",
      namePlaceholder: "Name",
      emailPlaceholder: "Email",
      buttonText: loading ? "Sending..." : "Get the E-book",
      oldPrice: "9.99€",  // Prix rayé
      bottomText: "(Limited Time Offer)",
      errorMessage: "This field is required",
    }
  };

  const text = labels[lang];

  // Fonction de soumission pour envoyer les données à l'API
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setLoading(true);

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setLoading(false);
      return;
    }

    try {
      // Exécuter reCAPTCHA et obtenir le token
      const token = await executeRecaptcha('lead_magnet_form');
      console.log('Token reCAPTCHA obtenu :', token);

      // Créer un objet incluant les données du formulaire et le token
      const dataWithToken = { ...data, token };

      // Envoyer les données au serveur
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataWithToken),
      });

      const result = await response.json();

      if (response.ok) {
        // Si l'inscription réussit, fermer le modal et afficher le ThankYouModal
        onRequestClose(); // Ferme le LeadMagnetModal
        setShowThankYou(true); // Affiche le ThankYouModal
      } else {
        console.error("Erreur lors de l'envoi des données", result);
      }
    } catch (error) {
      console.error("Une erreur s'est produite", error);
    }

    setLoading(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Lead Magnet"
        className="fixed inset-0 z-50 flex items-center justify-center p-4"
        overlayClassName="bg-black bg-opacity-75 fixed inset-0 z-40"
        ariaHideApp={false}
      >
        <div className="relative w-full max-w-md p-6 text-white rounded-lg shadow-lg" style={{ backgroundColor: '#1d1d1d' }}>
          <button onClick={onRequestClose} aria-label="Close" className="absolute top-4 right-4">
            <svg className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.75 14.25L14.25 21.75M14.25 14.25L21.7499 21.75" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M33 18C33 25.0711 33 28.6066 30.8033 30.8033C28.6066 33 25.0711 33 18 33C10.9289 33 7.3934 33 5.1967 30.8033C3 28.6066 3 25.0711 3 18C3 10.9289 3 7.3934 5.1967 5.1967C7.3934 3 10.9289 3 18 3C25.0711 3 28.6066 3 30.8033 5.1967C32.2639 6.65731 32.7533 8.70983 32.9173 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
          </button>

          <h2 className="mb-4 text-2xl font-bold text-center">{text.title}</h2>
          <hr className="mb-6 border" style={{ borderColor: 'var(--violet)' }} />

          <div className="flex justify-center mb-6">
            <Image
              src="/documents/EbookPopup.webp"
              alt="Ebook Preview"
              width={150}
              height={150}
            />
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <input
              type="text"
              placeholder={text.namePlaceholder}
              {...register('name', { required: true })}
              className="w-full px-4 py-2 text-white border border-white rounded-lg"
              style={{ backgroundColor: '#1d1d1d' }}
            />
            {errors.name && <p className="text-sm text-red-500">{text.errorMessage}</p>}

            <input
              type="email"
              placeholder={text.emailPlaceholder}
              {...register('email', { required: true })}
              className="w-full px-4 py-2 text-white border border-white rounded-lg"
              style={{ backgroundColor: '#1d1d1d' }}
            />
            {errors.email && <p className="text-sm text-red-500">{text.errorMessage}</p>}

            <button
              type="submit"
              className="w-full py-2 font-bold text-white rounded-lg"
              style={{ backgroundColor: 'var(--violet)' }}
              disabled={loading}
            >
              {text.buttonText}
            </button>
          </form>

          <p className="mt-6 text-center text-gray-400">
            <span className="line-through">{text.oldPrice}</span> {text.bottomText}
          </p>
        </div>
      </Modal>

      {/* Affichage du Thank You Modal après soumission réussie */}
      <ThankYouModal
        isOpen={showThankYou}
        onRequestClose={() => setShowThankYou(false)}
      />
    </>
  );
};

export default LeadMagnetModal;
